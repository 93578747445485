import store from '@/store/index'

function getDetallesParaServer(detalles) {
    var detalles_pedido_formato_server = []
    for (let i = 0; i < detalles.length; i++) {
        if (detalles[i].producto) {
            detalles_pedido_formato_server.push({
                producto: detalles[i].producto.id,
                cantidad: detalles[i].cantidad,
                iva: detalles[i].vat_type,
                precio_unitario: detalles[i].precioUnitario,


            });
        } else {

            
            detalles_pedido_formato_server.push({
                promocion: typeof detalles[i].promocion === 'object' ? detalles[i].promocion.id : detalles[i].promocion,
                cantidad: detalles[i].cantidad,
                iva: detalles[i].vat_type,
                precio_unitario: detalles[i].precioUnitario,

            });
        }
    }
    return detalles_pedido_formato_server
}
function quitDetalle(detalles, detalle) {
    const index = detalles.indexOf(detalle);

    if (index > -1) {
        // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor

        detalles.splice(index, 1);
        return detalles

    }

}
function controlaStock(detalles, detalle) {
    const cantidadDetalle = detalles[i].cantidad + detalle.cantidad
    if (detalle.producto.cantidadstock < cantidadDetalle) {
        console.warn("Stock insuficiente")
        return false
    }
    return true
}
function addDetalle(detalles, detalle, overwrite = false) {
    /*  ##  Hay dos formas de agregar detalle ##
        1º - Sumarle uno a la cantidad actual (si el detalle existe, sino se crea y cantidad igual a 1)
        2º - Asignarle una cantidad predefinida al detalle.
        La variable que define una u otra es overwrite
    */
    const moduloInventario = store.state.sucursales.configuracion.productoInventario
    
    if (detalle.producto) {
        console.log(detalle.producto);
        let index = detalles.find(d => d.producto && d.producto.id == detalle.producto.id)
        if (index) {
            for (var i in detalles) {
                console.log(detalles[i].producto);
                
                if (detalles[i].producto.id == detalle.producto.id) {
                    if (overwrite) {
                        if (moduloInventario && detalle.producto.controlaStock) {
                            if (detalle.producto.cantidadstock >= parseInt(detalle.cantidad)) {
                                detalles[i].cantidad = detalle.cantidad
                                store.commit('pedidos/set_detalle_repetido')
                            }else{
                                return "Stock insuficiente"
                            }
                        }else{
                            detalles[i].cantidad = detalle.cantidad
                            store.commit('pedidos/set_detalle_repetido')
                        }
                        
                    } else {
                        var cantidad = 1
                        console.log(detalle.producto);
                        console.log(detalle.producto.categoriaArbol.name.includes("PIZZA"));
                        if (detalle.producto.categoriaArbol.name.includes("PIZZA")){
                             cantidad = 0.5
                        }
                       
                       
                        const cantidadDetalle = parseFloat(detalles[i].cantidad) + parseFloat(cantidad)
                        if (moduloInventario && detalle.producto.controlaStock) {
                            if (detalle.producto.cantidadstock < cantidadDetalle) {
                                console.warn("Stock insuficiente")
                                return "Stock insuficiente"

                            }
                        }
                        detalles[i].cantidad = cantidadDetalle;
                    }
                }
            }
        }
        else {
            if (detalle.producto.categoriaArbol.name.includes("PIZZA")){
                cantidad = 0.5
                detalle.cantidad = cantidad
           }
            detalles.push(detalle);
        }
    } else {
        console.log(detalle);

        let index = detalles.find(d => d.promocion && d.promocion.id == detalle.promocion.id)
        if (index) {
            for (var i in detalles) {
                const cantidadDetalle = detalles[i].cantidad + 1
                detalles[i].cantidad = cantidadDetalle;
                // if (detalles[i].promocion.id == detalle.promocion.id) {
                //     detalles[i].cantidad = detalle.cantidad;
                //     break; //Stop this loop, we found it!
                // }
            }
            // store.commit('pedidos/set_detalle_repetido')
        }
        else {
            detalles.push(detalle);
        }
    }


}

function addDetalleCompra(detalles, detalle, overwrite = false) {
    // Comprobar si el detalle tiene un producto asociado
    if (detalle.producto) {
        // Buscar si el producto ya está en la lista de detalles
        let index = detalles.find(d => d.producto && d.producto.id == detalle.producto.id);
        
        if (index) {
            // Si el producto ya existe en la lista, actualizar la cantidad
            for (let i in detalles) {
                if (detalles[i].producto.id === detalle.producto.id) {
                    if (overwrite) {
                        // Si overwrite es true, sobrescribir la cantidad con la proporcionada en detalle
                        detalles[i].cantidad = detalle.cantidad;
                        store.commit('pedidos/set_detalle_repetido');
                    } else {
                        // Si overwrite es false, agregar a la cantidad existente (por defecto suma 1)
                        let cantidad = 1;

                        // Si el producto es de la categoría "PIZZA", la cantidad que se añade es 0.5
                        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
                            cantidad = 0.5;
                        }

                        // Actualizar la cantidad en el array de detalles
                        detalles[i].cantidad = parseFloat(detalles[i].cantidad) + cantidad;
                    }
                    break; // Salir del bucle una vez actualizado el detalle
                }
            }
        } else {
            // Si el producto no está en la lista, agregarlo como nuevo detalle
            if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
                detalle.cantidad = 0.5; // Definir cantidad inicial de 0.5 si es una pizza
            }
            detalles.push(detalle); // Añadir el detalle a la lista
        }
    } else {
        // Si no hay producto, buscar por promoción
        let index = detalles.find(d => d.promocion && d.promocion.id == detalle.promocion.id);
        
        if (index) {
            // Si la promoción ya está en la lista, sumar a la cantidad
            for (let i in detalles) {
                if (detalles[i].promocion.id === detalle.promocion.id) {
                    detalles[i].cantidad += 1;
                    break;
                }
            }
        } else {
            // Si la promoción no está en la lista, agregarla como nuevo detalle
            detalles.push(detalle);
        }
    }
}

function updateDetalle(detalles, detalle) {
    const index = detalles.indexOf(detalle);
    if (detalle.producto) {
        for (var i in detalles) {
            if (detalles[i].producto.id == detalle.producto.id) {
                detalles[i] = detalle;
                break; //Stop this loop, we found it!
            }
        }
    } else {
        for (var i in detalles) {
            if (detalles[i].promocion.id == detalle.promocion.id) {
                detalles[i] = detalle;
                break; //Stop this loop, we found it!
            }
        }
    }

}





export { quitDetalle, updateDetalle, addDetalle, addDetalleCompra, getDetallesParaServer }