
export function SET_PEDIDO_CLIENTE_VENTA(state, clienteVenta) {
    state.pedidoClienteVenta = clienteVenta;
}
export function SET_PEDIDO_CLIENTE_DIRECCION(state, clienteDireccion) {
    state.pedidoClienteDireccion = clienteDireccion;
}
export function SET_PEDIDO_ES_PARA_ENVIAR(state, esParaEnviar) {
    state.pedidoEsParaEnviar = esParaEnviar;
}
export function RESET_CLIENTE(state){
    state.pedidoClienteVenta = null;
    state.pedidoClienteDireccion = null;
    state.pedidoEsParaEnviar = false;

}

export function ACTUALIZAR_ESTADO_PEDIDO_VENTA(state, pedidoActualizado) {
    console.log("pedidoActualizado: ", pedidoActualizado);
    const { id, getEstadosActual } = pedidoActualizado;
    console.log("getEstadosActual", getEstadosActual);
  
    // Función para remover el pedido de una lista
    const removePedidoFromList = (list, id) => {
      const index = list.findIndex(pedido => pedido.id === id);
      if (index !== -1) {
        list.splice(index, 1);
      }
    };
  
    // Remover el pedido de la lista de pendientes si existe
    removePedidoFromList(state.pedidos_venta_pendientes, id);
    // Remover el pedido de la lista de aprobados si existe
    removePedidoFromList(state.pedidos_venta_aprobados, id);
  
    // Agregar el pedido a la lista correspondiente según su estado actual
    if (getEstadosActual === "APROBADO") {
        state.pedidos_venta_aprobados.push(pedidoActualizado);
        // Ordenar la lista de aprobados por fecha de actualización
        state.pedidos_venta_aprobados.sort((a, b) => new Date(b.fechaAlta) - new Date(a.fechaAlta));
      } else {
        state.pedidos_venta_pendientes.push(pedidoActualizado);
        // Ordenar la lista de pendientes por fecha de actualización
        state.pedidos_venta_pendientes.sort((a, b) => new Date(b.fechaAlta) - new Date(a.fechaAlta));
      }
    
  
  }

export function set_pedidos_compra(state, { estado, pedidos }) {
    estado = estado.toUpperCase()
    switch (estado) {
        case 'PENDIENTE':
            state.pedidos_compra_pendientes = []
            state.pedidos_compra_pendientes = pedidos
            break;
        case 'APROBADO':
            state.pedidos_compra_aprobados = []
            state.pedidos_compra_aprobados = pedidos
            break;
        case 'FINALIZADO':
            state.pedidos_compra_finalizados = []
            state.pedidos_compra_finalizados = pedidos
            break;

        default:
            break;
    }


}
export function set_pedidos_venta(state, { estado, pedidos }) {
    if (!estado) {
        state.pedidos_venta = []
        state.pedidos_venta = pedidos
        return
    }
    estado = estado.toUpperCase()

    switch (estado) {
        case 'PENDIENTE':
            state.pedidos_venta_pendientes = []
            state.pedidos_venta_pendientes = pedidos
            break;
        case 'APROBADO':
            state.pedidos_venta_aprobados = []
            state.pedidos_venta_aprobados = pedidos
            break;
        case 'FINALIZADO':
            state.pedidos_venta_finalizados = []
            state.pedidos_venta_finalizados = pedidos
            break;

        default:
            break;
    }


}

export function set_pedidos_aprobados(state, pedidos) {
    state.pedidos_aprobados = []
    state.pedidos_aprobados = pedidos
}
export function finalizados(state, pedidos) {
    state.pedidos_finalizados = []
    state.pedidos_finalizados = pedidos
}
export function set_pedido_con_detalle(state, pedidos) {
    state.pedido_con_detalle = null
    state.pedido_con_detalle = pedidos
}

export function add_pedido(state, pedido) {
    state.pedidos_compra_pendientes.push(pedido)
}

export function set_bandera_success_register(state) {

    state.register_success = true

    setTimeout(() => {
        state.register_success = false
    }, 1000);
}




// VENTA
export function set_pedido_venta_register(state, { cliente, envio, detalles }) {
    state.pedido_venta_register.cliente = cliente
    state.pedido_venta_register.envio = envio
    state.pedido_venta_register.detalle_pedido = detalles
}

export function reset_state_pedido_venta(state) {

    state.pedido_venta_register.cliente = {
        nombre: null,
        telefono: null,
        paraEnviar: false,
    },
        state.pedido_venta_register.envio = {
            selected: "",
            calle: "",
            altura: "",
            piso: "",
            depto: "",
            observacionEnvio: "",
        },
        state.pedido_venta_register.detalle_pedido = []
}


// PEDIDO COMPRA
export function set_pedido_compra_register(state, { proveedor, detalles }) {
    state.pedido_compra_register.proveedor = proveedor
    state.pedido_compra_register.detalle_pedido = detalles


}
export function set_proveedor_pedido_compra(state, proveedor) {
    state.pedido_compra_register.proveedor = proveedor
}

export function reset_state_pedido_compra(state) {

    state.pedido_compra_register.proveedor = null
    state.pedido_compra_register.detalle_pedido = []
}

///

export function quit_detalle(state, { tipoComprobante, detalle }) {


    var detalles_pedido = tipoComprobante === 'compras' ? state.pedido_compra_register.detalle_pedido : state.pedido_venta_register.detalle_pedido

    const index = detalles_pedido.indexOf(detalle);

    if (index > -1) {
        // index_register contiene el indice del array detalle_pedido, que se utiliza para enviar en formato correcto al servidor

        detalles_pedido.splice(index, 1);

    }
}

export function update_detalle(state, { tipoComprobante, detalle }) {


    var detalles_pedido = tipoComprobante === 'compras' ? state.pedido_compra_register.detalle_pedido : state.pedido_venta_register.detalle_pedido


    for (var i in detalles_pedido) {
        if (detalles_pedido[i].producto.id == detalle.producto.id) {
            detalles_pedido[i] = detalle;
            break; //Stop this loop, we found it!
        }
    }

    // index contiene el indice del arra yviews_detalle, que es el mismo de detalle_pedido, que se utiliza para enviar en formato correcto al servidor

    // const index = detalles_pedido.indexOf(detalle);


    // if (index > -1) {


    //     detalles_pedido.splice(index, 1, detalle);


    // }


    // call form submission logic

}
export function set_detalle_repetido(state) {



    state.error_detalle_repetido.state = true
    setTimeout(() => {
        state.error_detalle_repetido.state = false

    }, 3000);
}

export function add_detalle(state, { tipoComprobante, detalle }) {
    console.log("DETALLEEE", detalle);
    console.log("tipoComprobante", tipoComprobante);



    var detalles_pedido = tipoComprobante === 'compras' ? state.pedido_compra_register.detalle_pedido : state.pedido_venta_register.detalle_pedido

    let index = detalles_pedido.find(d => d.producto.id == detalle.producto.id)


    if (index) {
        for (var i in detalles_pedido) {
            if (detalles_pedido[i].producto.id == detalle.producto.id) {
                detalles_pedido[i].cantidad = detalle.cantidad;
                break; //Stop this loop, we found it!
            }
        }
        state.error_detalle_repetido.state = true

        setTimeout(() => {
            state.error_detalle_repetido.state = false

        }, 3000);

    }
    else {
        detalles_pedido.push(detalle);
    }
}

// ACTUALIZACION DE PEDIDO -------------------------------------------------------------------
export function clean_detalles_update(state) {

    state.detalles_update = []
    state.pedido_con_detalle = []

}
//No se estan utilizando
export function UPDATE_CANTIDAD(state, { productoId, cantidad }) {
    console.log("UPDATE_CANTIDAD", productoId, cantidad);
    
    let detalle = state.detalles_update.find(d => d.producto === productoId)
    if (detalle) {
        detalle.cantidad += cantidad;
    } else {
        console.warn("Detalle no encontrado en detalles_pedido.");
    }
    console.log("detalle cantidad final", detalle);
    
}
export function add_detalle_update(state, detalle) {

    
    state.detalles_update.push(detalle);

    // //Accion 0: Eliminar, 1:Modificar y 2: Agregar

    // let detalle_pedido = {
    //     producto: detalle.producto.id,
    //     cantidad: detalle.cantidad,
    //     accion: 2
    // }

    // let existeDetalle = state.detalles_update.find(d => d.producto == detalle_pedido.producto);
    // console.log("Agregar detalle");

    // if (existeDetalle) {
    //     console.log("Existe el detalle para enviar, es: ", existeDetalle);

    //     //El detalle existia y fue eliminado
    //     if (existeDetalle.accion == 0) {
    //         console.log("Esta en el server, y fue eliminado");
    //         console.log("Pero ahora se agrego de nuevo, y es modificado");


    //         //Tiene que cambiar a la accion de modificar, y setear la cantidad
    //         for (var i in state.detalles_update) {
    //             if (state.detalles_update[i].producto == detalle.producto.id) {
    //                 state.detalles_update[i].accion = 1
    //                 state.detalles_update[i].cantidad = detalle_pedido.cantidad;

    //                 break; //Stop this loop, we found it!
    //             }
    //         }
    //         //Tambien debe mostrarlo
    //         state.pedido_con_detalle.detalles_pedido.push(detalle);


    //     } else {
    //         console.log("Existe el detalle, y fue modificado");

    //         // Si existe para enviar y no fue por que se elimino
    //         //Actualizo la cantidad para enviar
    //         for (var i in state.detalles_update) {
    //             if (state.detalles_update[i].producto == detalle.producto.id) {

    //                 state.detalles_update[i].cantidad = detalle_pedido.cantidad;

    //                 break; //Stop this loop, we found it!
    //             }
    //         }
    //         //Actualizo la cantidad para mostrar

    //         for (var i in state.pedido_con_detalle.detalles_pedido) {
    //             // es el id del detalle
    //             if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

    //                 state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;
    //                 break;
    //             }
    //         }
    //     }

    // }
    // else {
    //     console.log("No existe el detalle para enviar, es un detalle nuevo");


    //     let index = state.pedido_con_detalle.detalles_pedido.find(d => d.producto.id == detalle.producto.id)

    //     if (index) {
    //         // let detalle_pedido = {
    //         //     detallePedido_id: detalle.id,
    //         //     producto: detalle.producto.id,
    //         //     cantidad: detalle.cantidad,
    //         //     accion: 1
    //         // }
    //         detalle_pedido.accion = 1
    //         //Se tiene que agregar en los detalles que se envian al servidor porque no existian
    //         //como una modificacion
    //         //Actualizo la cantidad para mostrar
    //         state.detalles_update.push(detalle_pedido);
    //         for (var i in state.pedido_con_detalle.detalles_pedido) {
    //             // es el id del detalle
    //             if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

    //                 state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;
    //                 break;
    //             }
    //         }
    //         state.error_detalle_repetido.state = true

    //         setTimeout(() => {
    //             state.error_detalle_repetido.state = false

    //         }, 3000);


    //     }
    //     else {
    //         state.detalles_update.push(detalle_pedido);
    //         state.pedido_con_detalle.detalles_pedido.push(detalle);

    //         // state.detalles_views_update.push(detalle);
    //     }

    // }
}
export function update_detalle_update(state, detalle) {


    // let detalle_pedido = {
    //     detallePedido_id: detalle.id,
    //     producto: detalle.producto.id,
    //     cantidad: detalle.cantidad,
    //     accion: 1
    // }


    // // Busca si ya existe el detalle 
    // let existeDetalle = state.detalles_update.find(d => d.producto == detalle_pedido.producto);

    // if (existeDetalle) {

    //     for (var i in state.detalles_update) {
    //         if (state.detalles_update[i].producto == detalle.producto.id) {

    //             state.detalles_update[i].cantidad = detalle_pedido.cantidad;

    //             break; //Stop this loop, we found it!
    //         }
    //     }

    //     for (var i in state.pedido_con_detalle.detalles_pedido) {
    //         // es el id del detalle
    //         if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

    //             state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;
    //             break;
    //         }
    //     }
    // }
    // else {
    //     state.detalles_update.push(detalle_pedido);
    //     for (var i in state.pedido_con_detalle.detalles_pedido) {
    //         // es el id del detalle
    //         if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {

    //             state.pedido_con_detalle.detalles_pedido[i].cantidad = detalle_pedido.cantidad;

    //             break; //Stop this loop, we found it!
    //         }
    //     }

    // }




}
export function quit_detalle_update(state, detalle) {

    let detalle_pedido = {
        detallePedido_id: detalle.id,
        producto: detalle.producto.id,
        cantidad: detalle.cantidad,
        accion: 0
    }
    let existeDetalleParaEnviar = state.detalles_update.find(d => d.producto == detalle_pedido.producto);

    if (existeDetalleParaEnviar) {
        //"Esta en para enviar");

        if (existeDetalleParaEnviar.accion == 1) {
            //"Y fue modificado, por lo que existia ya en el server");
            //"Lo seteo como eliminado nuevamente");
            for (var i in state.detalles_update) {
                if (state.detalles_update[i].producto == detalle.producto.id) {
                    state.detalles_update[i].accion = 0
                    state.detalles_update[i].cantidad = detalle_pedido.cantidad;

                    break; //Stop this loop, we found it!
                }
            }
            // lo quito de la vista");


            for (var i in state.pedido_con_detalle.detalles_pedido) {
                // es el id del detalle
                if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {
                    state.item_para_deshacer.para_mostrar = state.pedido_con_detalle.detalles_pedido[i]

                    state.pedido_con_detalle.detalles_pedido.splice(i, 1);
                    break;
                }

            }
            state.deshacer.state = true

            setTimeout(() => {
                state.deshacer.state = false
                state.item_para_deshacer.para_mostrar = {}
                state.item_para_deshacer.para_enviar = {}

            }, 250000);


        } else {
            //Este detalle es nuevo, lo quito del para enviar");
            for (var i in state.detalles_update) {
                if (state.detalles_update[i].producto == detalle.producto.id) {
                    state.item_para_deshacer.para_enviar = state.detalles_update[i]
                    state.detalles_update.splice(i, 1);

                    break; //Stop this loop, we found it!
                }
            }
            //"Tambien lo quito para mostrar");

            for (var i in state.pedido_con_detalle.detalles_pedido) {
                // es el id del detalle
                if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {
                    state.item_para_deshacer.para_mostrar = state.pedido_con_detalle.detalles_pedido[i]

                    state.pedido_con_detalle.detalles_pedido.splice(i, 1);
                    break;
                }

            }
            state.deshacer.state = true

            setTimeout(() => {
                state.deshacer.state = false
                state.item_para_deshacer.para_mostrar = {}
                state.item_para_deshacer.para_enviar = {}

            }, 250000);

        }

    } else {
        /*El detalle no existia para enviar, se eliminara y");
        se guardara en para enviar. y lo quito de la vista");*/
        state.detalles_update.push(detalle_pedido);
        for (var i in state.pedido_con_detalle.detalles_pedido) {
            // es el id del detalle
            if (state.pedido_con_detalle.detalles_pedido[i].producto.id == detalle.producto.id) {
                state.item_para_deshacer.para_mostrar = state.pedido_con_detalle.detalles_pedido[i]
                state.item_para_deshacer.para_enviar = detalle_pedido

                state.pedido_con_detalle.detalles_pedido.splice(i, 1);
                break;
            }
        }
        state.deshacer.state = true

        setTimeout(() => {
            state.deshacer.state = false
            state.item_para_deshacer.para_mostrar = {}
            state.item_para_deshacer.para_enviar = {}

        }, 250000);
    }
}
// ---------
export function set_item_para_deshacer_para_mostrar(state, detalle_pedido) {
    state.item_para_deshacer.para_mostrar = detalle_pedido

}

export function set_item_para_deshacer_para_enviar(state, detalle_update) {
    state.item_para_deshacer.para_enviar = detalle_update

}
export function active_item_para_deshacer(state) {
    state.deshacer.state = true

    setTimeout(() => {
        state.deshacer.state = false
        state.item_para_deshacer.para_mostrar = {}
        state.item_para_deshacer.para_enviar = {}

    }, 250000);

}
export function close_item_para_deshacer(state) {

    state.deshacer.state = false


}






