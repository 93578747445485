
export default [
  {
    path: '/reportes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ReportesHome.vue'),
    children: [
      {
        path: '',
        name: 'reporteInformeMensual',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ventas/ReporteGanancias.vue'),
         meta: {navHome: false, }



      },
      {
        path: 'ventas/hora',
        name: 'reportesVentasPorHora',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ventas/ReporteVentasPorHora.vue'),


      },
      {
        path: 'ventas/mensual',
        name: 'reportesVentasPorMes',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ventas/ReporteVentaPorMes.vue'),


      },
      {
        path: 'ventas/periodo',
        name: 'reportesVentasPeriodo',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ventas/reporte.ventas.por.periodo.vue'),

      },
      {
        path: 'ventas/anuladas',
        name: 'reportesVentasAnuladas',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ventas/reporte.ventas.anuladas.vue'),

      },

      {
        path: 'compras',
        name: 'reportesCompras',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/reporte.compras.vue'),

      },
      {
        path: 'resumen',
        name: 'reporteArqueo',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/arqueoCaja/reporte.arqueo.caja.vue'),

      },
      {
        path: 'resumen/detalle',
        name: 'reportesResumenDetalle',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/resumen/reporte.resumen.arqueo.vue'),


      },
      {
        path: 'resumen/detalle/ventas',
        name: 'detalleVentas',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/resumen/reporte.resumen.arqueo.ventas.vue'),


      },
      {
        path: 'resumen/detalle2',
        name: 'reportesResumenDetalle2',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/resumen/reporte.resumen.arqueo.2.vue'),


      },
      {
        path: 'productos',
        name: 'reportesProductos',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/reporte.productos.vue'),

      },
      {
        path: 'productos/planilla-gondola',
        name: 'reportePlanillaGondola',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/productos/reporte.planilla.gondola.vue'),

      },
      {
        path: 'productos/nueva-lista',
        name: 'reportesProductosGenerarLista',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/productos/ReporteProductosGenerarListaPrecios.vue'),

      },
      {
        path: 'egresoIngreso',
        name: 'reporteEgresoIngreso',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/reporte.egreso.ingreso.vue'),

      },
      {
        path: 'gastos',
        name: 'reporteGastos',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/reporte.gastos.vue'),
      },
      {
        path: 'gastos/detalle',
        name: 'reporteGastosDetalle',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/ReporteGastosDetalle.vue'),


      },
      {
        path: 'contable/cuenta',
        name: 'reporteCuentaContable',

        component: () => import(/* webpackChunkName: "about" */ '@/views/reportes/contable/ReporteCuentaContable.vue'),


      },
     




    ],
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, navHome: false, }


  },

]